<template>
<div class="container">
<div ref="containerTopbing" class="containerTopbing" >
<a href="" target="_blank" v-if="viewImgTop">
<img :src="content.fileName" style="width: 100%"  />
</a>
</div>
<div class="containerWrap" :style="containerStyle">
<div class="containerTop"></div>
<div class="header">
<div class="wrap clearfloat">
<div class="nav_box">
<ul class="clearfloat">
<div class="nav_grob">
<li :class="{'active':active == 0}">
<h2 style="width: 60px;">
<router-link to="/">首页</router-link>
</h2>
</li>
</div>
<li v-for="(item, index) in navList" :key="index" :class="{'active':active == item.id}">
<div class="nav_grop" @mouseenter="navShow(item.channelId)" @mouseleave="navClose()">
<h2>
<a v-if="item.child.length && item.child[0].url && item.child[0].url !== ''" target="_blank" :href="item.url">
                      {{item.name}}
                    </a>
<a v-if="item.child.length && (!item.child[0].url || item.child[0].url == '')" :href="item.catalog == 1 ? `/listPage/${item.child[0].entTChannelUuid}` : item.catalog == 2 ? `/list/${item.child[0].entTChannelUuid}` : `/listPic/${item.child[0].entTChannelUuid}`">
                      {{item.name}}
                    </a>
<a v-if="!item.child.length" :href="item.catalog == 1 ? `/listPage/${item.entTChannelUuid}` : item.catalog == 2 ? `/list/${item.entTChannelUuid}` : `/listPic/${item.entTChannelUuid}`">
                      {{item.name}}
                    </a>
</h2>
<el-collapse-transition>
<div class="nav_down" v-show="navDown == item.channelId">
<div class="nav_down_wrap clearfloat" v-show="item.child.length != 0">
<p v-for="(item1, index1) in item.child" :key="index1">
<a v-if="item1.url && item1.url !== ''" target="_blank" :href="item1.url">
                            {{item1.name}}
                          </a>
<a v-if="!item1.url || item1.url == ''" :href="item1.catalog === 1 ? `/listPage/${item1.entTChannelUuid}` : item1.catalog === 2 ? `/list/${item1.entTChannelUuid}` : `/listPic/${item1.entTChannelUuid}`">
                            {{item1.name}}
                          </a>
</p>
</div>
</div>
</el-collapse-transition>
</div>
</li>
</ul>
</div>
</div>
</div>
<div class="main">
<router-view></router-view>
</div>
<div class="footer">
<div class="wrap">
<div style="float:left">
<div class="footerRight">
<span>地址:兰州市西固区西固西路37号 邮编:730060 版权所有:千亿体育登录</span>
</div>
<div class="footerMiddle"><span> Email:gsyags@126.com 电话:0931-7301093 传真:0931-7301002</span></div>
<div class="footerRightInner">
<span>总访问量：<font color="#ffffff" style="padding-left: 7px;">876221</font>&nbsp;&nbsp;

</span>
</div>
<div class="footerdown">
<span><a href="https://beian.miit.gov.cn/" target="_blank">陇ICP备19004540号</a></span>
<span><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62010402000114" target="_blank" style="margin: 0px 0px 0px 15px;"><img src="../assets/img/ba.png" alt="" style="padding-right:3px;"> 甘公网安备 62010402000114号</a></span>
</div>
</div>
<div class="ewm">
<img src="../assets/img/yianewm.jpg">
</div>
</div>
</div>
</div>
</div>
</template>
<script>
  import {
    getNavChannel,
    getAdvert
  } from '@/api/common'

  export default {
    name: '',
    data() {
      return {
        containerStyle: {},
        txtImg: '',
        content: '',
        viewImgTop: true,
        searchValue: '',
        active: 0,
        navList: [],
        navListN: [{
            "id": 1,
            "name": "企业概况",
            "isIndex": 0,
            "url": '',
            "group_list": [{
                "id": 1,
                "name": "企业简介",
                "url": '',
                "nav_id": 2,
                "have_list": 1,
                "create_time": "2020-12-22T07:10:13.037Z"
              },
              {
                "id": 2,
                "name": "组织机构",
                "url": '',
                "nav_id": 2,
                "have_list": 1,
                "create_time": "2020-12-22T07:10:13.037Z"
              },
              {
                "id": 3,
                "name": "企业证件",
                "url": '',
                "nav_id": 2,
                "have_list": 1,
                "create_time": "2020-12-22T07:10:13.037Z"
              },
              {
                "id": 4,
                "name": "企业资质",
                "url": '',
                "nav_id": 2,
                "have_list": 1,
                "create_time": "2020-12-22T07:10:13.037Z"
              },
              {
                "id": 5,
                "name": "许可证书",
                "url": '',
                "nav_id": 2,
                "have_list": 1,
                "create_time": "2020-12-22T07:10:13.037Z"
              },
              {
                "id": 6,
                "name": "历史回眸",
                "url": '',
                "nav_id": 2,
                "have_list": 1,
                "create_time": "2020-12-22T07:10:13.037Z"
              },
              {
                "id": 7,
                "name": "公司领导",
                "url": '',
                "nav_id": 2,
                "have_list": 1,
                "create_time": "2020-12-22T07:10:13.037Z"
              }
            ]
          },
          {
            "id": 2,
            "name": "新闻动态",
            "isIndex": 0,
            "url": '',
            "group_list": [{
                "id": 8,
                "name": "甘肃建投",
                "url": '',
                "nav_id": 3,
                "have_list": 0,
                "create_time": "2020-12-22T07:11:14.391Z"
              },
              {
                "id": 9,
                "name": "集团新闻",
                "url": '',
                "nav_id": 3,
                "have_list": 0,
                "create_time": "2020-12-22T07:11:41.023Z"
              },
              {
                "id": 10,
                "name": "基层动态",
                "url": '',
                "nav_id": 3,
                "have_list": 0,
                "create_time": "2020-12-22T07:11:41.023Z"
              },
              {
                "id": 11,
                "name": "专题报道",
                "url": '',
                "nav_id": 3,
                "have_list": 0,
                "create_time": "2020-12-22T07:11:41.023Z"
              }
            ]
          },
          {
            "id": 3,
            "name": "企业业绩",
            "isIndex": 0,
            "url": '',
            "group_list": [{
                "id": 12,
                "name": "项目动态",
                "url": '',
                "nav_id": 3,
                "have_list": 0,
                "create_time": "2020-12-22T07:11:14.391Z"
              },
              {
                "id": 13,
                "name": "代表工程",
                "url": '',
                "nav_id": 3,
                "have_list": 0,
                "create_time": "2020-12-22T07:11:14.391Z"
              },
              {
                "id": 14,
                "name": "企业荣誉",
                "url": '',
                "nav_id": 3,
                "have_list": 0,
                "create_time": "2020-12-22T07:11:14.391Z"
              }
            ]
          },
          {
            "id": 4,
            "name": "合规管理",
            "isIndex": 0,
            "url": '',
            "group_list": [{
                "id": 15,
                "name": "内部管理",
                "url": '',
                "nav_id": 4,
                "have_list": 0,
                "create_time": "2020-12-22T07:12:55.466Z"
              },
              {
                "id": 16,
                "name": "通知公告",
                "url": '',
                "nav_id": 4,
                "have_list": 0,
                "create_time": "2020-12-22T07:12:55.466Z"
              }
            ]
          },
          {
            "id": 5,
            "name": "党的建设",
            "isIndex": 0,
            "url": '',
            "group_list": []
          },
          {
            "id": 6,
            "name": "智能管理",
            "isIndex": 0,
            "url": '',
            "group_list": [{
                "id": 17,
                "name": "科技创新",
                "url": '',
                "nav_id": 6,
                "have_list": 0,
                "create_time": "2020-12-22T07:12:55.466Z"
              },
              {
                "id": 18,
                "name": "BIM技术应用",
                "url": '',
                "nav_id": 6,
                "have_list": 0,
                "create_time": "2020-12-22T07:12:55.466Z"
              },
              {
                "id": 19,
                "name": "材料集中管控",
                "url": '',
                "nav_id": 6,
                "have_list": 0,
                "create_time": "2020-12-22T07:12:55.466Z"
              }
            ]
          },
          {
            "id": 7,
            "name": "人力资源",
            "isIndex": 0,
            "url": '',
            "group_list": [{
                "id": 20,
                "name": "人才招聘",
                "url": '',
                "nav_id": 7,
                "have_list": 0,
                "create_time": "2020-12-22T07:12:55.466Z"
              },
              {
                "id": 21,
                "name": "联系我们",
                "url": '',
                "nav_id": 7,
                "have_list": 0,
                "create_time": "2020-12-22T07:12:55.466Z"
              }
            ]
          },
          {
            "id": 8,
            "name": "员工风采",
            "isIndex": 0,
            "url": '',
            "group_list": [{
                "id": 22,
                "name": "劳模风采",
                "url": '',
                "nav_id": 8,
                "have_list": 0,
                "create_time": "2020-12-22T07:12:55.466Z"
              },
              {
                "id": 23,
                "name": "优秀党员",
                "url": '',
                "nav_id": 8,
                "have_list": 0,
                "create_time": "2020-12-22T07:12:55.466Z"
              },
              {
                "id": 24,
                "name": "十佳青年",
                "url": '',
                "nav_id": 8,
                "have_list": 0,
                "create_time": "2020-12-22T07:12:55.466Z"
              },
              {
                "id": 25,
                "name": "一安工匠",
                "url": '',
                "nav_id": 8,
                "have_list": 0,
                "create_time": "2020-12-22T07:12:55.466Z"
              }
            ]
          }
        ],
        navDown: 0,

      }
    },
    mounted() {
      this.getPath()
      this.footerImg()
      this.getNavChannel()

      let url = this.$route.name
      if (url == 'home') {
        this.srollimg()
      }
    },
    created() {
      // let url = this.$route.name
      // if (url == 'home') {
      //   this.srollTop()
      // }
    },
    watch: {
      '$route': 'getPath'
    },
    methods: {
      srollimg() {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        advertType: 4
      }
      getAdvert(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          data.length && (_this.content = data[0])
          this.$nextTick(() => {
            this.srollTop()
          })
        }
      })
    },
      srollTop() {
        var _this = this
        var heightT
        setTimeout(() => {
          heightT = this.$refs.containerTopbing.offsetHeight
          _this.containerStyle = {
            marginTop: heightT + 'px'
          }
        }, 500)
        setTimeout(() => {
          _this.containerStyle = {
            marginTop: 0
          }
        }, 3000)
        setTimeout(() => {
          _this.viewImgTop = false
        }, 6000)
      },
      // 获取导航
      getNavChannel() {
        var _this = this
        let data = {
          applicationUuid: _this.webId
        }
        getNavChannel(data).then(res => {
          const {
            code,
            data
          } = res
          if (code === 1 && data) {
            _this.navList = data
            // data.length &&
            //   data.forEach((line, index) => {
            //     if (line.child.length) {
            //       line.url = _this.navList[index].index + '/' + line.child[0].entTChannelUuid
            //     } else {
            //       line.url = _this.navList[index].index + '/' + line.entTChannelUuid
            //     }
            //   })
            // _this.navSubList = [
            //   {
            //     id: 1,
            //     name: '网站首页',
            //     url: '/'
            //   },
            //   ...data
            // ]
          }
        })
      },
      handleSearch() {
        var _this = this
        if (!_this.searchValue || _this.searchValue == '') {
          this.$message({
            message: '请输入关键词',
            type: 'warning'
          })
        } else {
          window.location.href = `/search/${_this.searchValue}`
          // window.open(`/search/${_this.searchValue}`)
        }
      },
      // 二级导航展开
      navShow(d) {
        let _this = this
        // _this[d] = true
        _this.navDown = d
      },
      // 二级导航收起
      navClose() {
        let _this = this
        // _this[d] = false
        _this.navDown = 0
      },
      haddleNav(index, params) {
        var _this = this

        _this.active = index
        _this.$router.push(params.url)
      },
      getPath() {
        var _this = this

        _this.active = _this.$route.meta.index
      },
      footerImg() {
        var _this = this

        _this.txtImg = unescape("%3Cspan id='cnzz_stat_icon_1259563611'%3E%3C/span%3E%3Cscript src='https://s4.cnzz.com/stat.php%3Fid%3D1259563611%26show%3Dpic' type='text/javascript'%3E%3C/script%3E")
        console.log('txtImg :>> ', _this.txtImg);
      }
    }
  }
</script>
<style>
.containerTopbing {
    position: fixed;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    z-index: -10;
    overflow: hidden;
    -moz-transition: all 2s ease-out;
    -webkit-transition: all 2s ease-out;
    -o-transition: all 2s ease-out;
    transition: all 2s ease-out;
}

.containerTopbing img {
    width: 100%;
    height: 100%;
    display: block;
}
.containerWrap {
    -moz-transition: all 2s ease-out;
    -webkit-transition: all 2s ease-out;
    -o-transition: all 2s ease-out;
    transition: all 2s ease-out;
    z-index: 10;
    background-color: #fff;
}
  .containerTop {
    height: 300px;
    background-image: url("../assets/img/yian01.jpg");
    background-position: center;
    background-repeat: no-repeat;
    width: 1000px;
    margin: 0 auto;
  }

  .containerTop .wrap {
    height: 100%;
    position: relative;
  }

  .containerTopL {
    margin-top: 48px;
  }

  .containerTopR a {
    display: inline-block;
    height: 34px;
    line-height: 34px;
    text-align: center;
    margin-left: 6px;
    padding: 0 12px;
    color: #fff;
    font-size: 16px;
    background-color: #0162B3;
    margin-top: 55px;
  }

  .header {
    width: 1000px;
    
    background-color: #0170c1;
    height: 50px;
    margin: 0 auto;
    
    
    
  }

  .headerLogo {
    margin-top: 6px;
    
  }

  .headerLogo img {
    
    height: 46px;
    width: auto;
    vertical-align: middle;
  }

  .headerNavTxt {
    display: inline-block;
    min-width: 45px;
    color: #fff;
    font-size: 20px;
    letter-spacing: 4px;
    cursor: pointer;
  }

  .headerNavTxt:hover {
    color: #BC8F68;
  }


  


  .main {
    width: 1000px;
    margin: 0 auto;
    overflow: hidden;
  }

  .footer {
    width: 1000px;
    color: #A1D4FF;
    margin: 0 auto;
    line-height: 30px;
    background-color: #0062B3;
    padding: 30px 0px;
    margin-top: 20px;
  }

  .footerWrap {
    padding: 16px 0 10px;
    text-align: left;
  }

  .footerRight,
  .footerMiddle,
  .footerRightInner {
    width: 760px;
    margin-left: 20px;
    text-align: center;
  }

  .footerdown {
    width: 390px;
    margin-left: 230px;
    padding: 0px;
  }

  .footerdown a {
    float: left;
    height: 20px;
    line-height: 20px;
    margin: 0px 0px 0px -20px;
    color: #FFFFFF;
  }

  .footerLeft {
    text-align: right;
  }

  .footerLeft .pic {
    text-align: center;
  }

  .footerLeft img {
    width: 130px;
    height: auto;
  }

  .footerLeft .txt {
    height: 30px;
    line-height: 30px;
    color: #80b2db;
    font-size: 13px;
  }

  .footerRightInner span {
    display: inline-block;
    margin-right: 30px;
  }

  .footerRightInner a {
    color: #fff;
  }

  .footer .ewm {
    width: 107px;
    height: 107px;
    float: right;
    margin-right: 110px;
  }

  .footerRightInner.bt,
  .footerRightInner.bt a {
    color: #80b2db;
  }

  .nav_box {
    
    
    width: 100%;
    margin: 0 auto;
    height: 50px;
  }

  .nav_box ul li {
    float: left;
  }

  .nav_box ul li a {
    display: block;
  }

  .nav_grop {
    
    height: 100%;
    
    position: relative;
    width: 117px;
  }

  .nav_grop h2 {
    text-align: center;
    height: 100%;
    line-height: 50px;
    height: 50px;
  }

  .nav_box ul li h2 {
    text-align: center;
    height: 100%;
    line-height: 50px;
  }

  .nav_box ul li h2 a {
    display: block;
    color: #fff;
    
    font-size: 15px;
  }

  .nav_grop:hover h2 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .nav_grop:hover h2 a {
    background-color: #A8D1EF;
    color: #0170c1;
  }

  .nav_down {
    position: absolute;
    z-index: 999;
  }

  .nav_down_wrap {
    
    width: 117px;
    text-align: center;
  }

  .nav_down p {
    line-height: 35px;
    text-align: center;
    font-size: 14px;
    background: #A8D1EF;
    border-top: 1px #ffffff solid;
  }

  .nav_down p a {
    display: block;
    color: #fff;
  }

  .nav_down p a:hover {
    background-color: #0170c1;
  }

  .header_hd {
    height: 34px;
    line-height: 34px;
    
    background-repeat: repeat-x;
    background-size: auto 100%;
    padding: 0 25px;
    font-size: 12px;
  }

  .header_top {
    padding: 10px;
  }

  .mobile {
    display: block;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    border: 1px solid #dedede;
    margin-top: 6px;
    font-size: 12px;
    font-weight: 600;
  }

  .mobile:hover {
    color: #d60f0f;
  }

  .user_input {
    display: inline-block;
    width: 120px;
  }

  .user_l {
    display: inline-block;
    margin-left: 10px;
  }

  .user_sure {
    margin: 0 4px 0 6px;
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    border: 1px solid #b6b6b6;
    cursor: pointer;
    vertical-align: top;
    margin-top: 3px;
    background-color: #e9e9e9;
    border-radius: 2px;
  }

  .user_sure:hover {
    background-color: #dddddd;
  }

  .user_forget:hover {
    color: #d60f0f;
    text-decoration: underline;
  }

  .mail_dsz {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    border: 1px solid #dedede;
    margin-top: 6px;
    font-size: 12px;
    color: #d60f0f;
    margin-left: 30px;
  }

  .mail_dsz:hover {
    border-color: #d60f0f;
  }

  .user_wrap {
    display: inline-block;
  }

  .footerBotttom {
    text-align: center;
  }

  .footer .wrap {
    width: 100%;
    text-align: center;
    overflow: hidden;
  }

  .topLogoMainRight {
    position: absolute;
    width: 380px;
    height: 50px;
    overflow: hidden;
    bottom: 10px;
    right: 0;
  }

  .topLogoMainRight .el-input__inner {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .topLogoMainRight .el-button {
    border: none;
  }

  .topLogoMainRight {
    float: right !important;
  }

  .header_search {
    width: 380px;
    height: 51px;
    overflow: hidden;
    float: right;
    position: relative;
  }

  .header_search input {
    width: 300px;
    height: 38px;
    overflow: hidden;
    outline: none;
    box-shadow: none;
    line-height: 38px;
    font-size: 14px;
    color: #000;
    border-radius: 0px;
    border: none;
  }

  .swiper-container-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    right: 0px;
  }

  .bannerHomeSec01 .swiper-pagination {
    right: 0%;
  }
</style>